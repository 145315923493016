#album {
  background-image: url("/assets/images/blurred_backgrounds/blur_bg_3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 10vw;
}

 #album-info {
  display: flex;
  flex-direction: row;
  padding-bottom: 4rem;
}

#album-cover-art {
  height: 18rem;
}

.album-details {
  padding: 3rem;
  text-align: left;
  font-family:'Comfortaa', cursive;
}

@media all and (max-width: 830px) {
  #album-info {
   flex-direction: column;
   margin: auto;
 }

 #album-cover-art {
   height: 18rem;
   width: 18rem;
 }

 .album-details {
   padding: 1rem 1rem 0;
 }
}

@media all and (max-width: 380px) {
  #album-cover-art {
    height: 14rem;
    width: 14rem;
  }
}
